import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import MobileMenu from "./MobileMenu/MobileMenu";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "./layout.css";

type SiteTitleQuery = {
    site: {
        siteMetadata: {
            title: string;
        };
    };
};

type LayoutProps = {
    children: React.ReactNode;
    location: string;
};

const Layout = ({ children, location }: LayoutProps) => {
    const data = useStaticQuery<SiteTitleQuery>(graphql`
        query SiteTitleQuery { site { siteMetadata { title } } }
    `);

    return (
        <div className="gradient">
            <MobileMenu location={location} />
            <div className="empaque">
                <div className="container">
                    <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
                    <main>{children}</main>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Layout;