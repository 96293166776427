import React from "react";
import * as styles from "./mobilemenu.module.css";
import { Link } from "gatsby";

type MobileMenuItemProps = {
    menu: {
        name: keyof typeof styles;
        items: {
            id: number;
            title: string;
            pageUrl: string;
        }[];
    };
    location: string;
};

const MobileMenuItem = ({ menu, location }: MobileMenuItemProps) => {
    let flag = false;
    for (const menuItem of menu.items) {
        if (menuItem.pageUrl === location) {
            flag = true;
            break;
        }
    }
    const subdivisionStyle = flag ? "" : styles.hidden;

    const items = menu.items.map(item =>
        <li key={"mobile-menu-item-" + item.id} className={(location === item.pageUrl ? styles.mobileMenuItemSelected : "") + (item.id === 0 ? ` ${styles.mobileMenuItemPrincipal}` : "")}>
            <Link to={item.pageUrl} dangerouslySetInnerHTML={{ __html: item?.title }} />
        </li>
    );

    return (
        <div className={`${styles.menuDerecha} ${styles[menu.name]} ${subdivisionStyle}`}>
            <ul>{items}</ul>
        </div>
    );
};

export default MobileMenuItem;